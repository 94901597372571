export class ModuleItemAttributeTranslationModel {
  id?: number;
  attributeId?: number;
  language?: string;
  identifier?: string;
  translation?: string;
  createdAt?: string;
  updatedAt?: string;

  saveModuleItemAttributeTranslationData(data) {
    this.id = data.id;
    this.attributeId = data.attribute_id;
    this.language = data.language;
    this.identifier = data.identifier;
    this.translation = data.translation;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
  }
}
