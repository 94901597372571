import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authorization = 'Authorization';
  private tokenName = 'frontend_token';

  constructor(private http: HttpClient, private router: Router) { }

  /**
   * Takes email and password entered by participant and save it into a body to call the login endpoint.
   * Also, a http options is created so that the right data is returned. This returns an observable.
   * @param email
   * @param password
   * @returns {Observable<any>}
   */
  login(email: string, password: string): Observable<any> {
    const body = {
      email: email,
      password: password
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }),
      observe: 'response' as 'response'
    };

    return this.http.post('login', body, httpOptions);
  }

  /**
   * sends request to api for forgot password and the api will send an email the participant with a link to reset the
   * password.
   * @param email
   */
  forgotPassword(email: string) {
    const body = {
      email: email
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }),
      observe: 'response' as 'response'
    };

    return this.http.post('pwd/forgot', body, httpOptions);
  }

  /**
   * Checks the headers of login endpoint and retrieves the auth token.
   * @param response
   */
  checkHeaders(response: any) {
    if (response.headers.get(this.authorization) || response.headers.get(this.authorization.toLowerCase())) {
      let token = response.headers.get(this.authorization) || response.headers.get(this.authorization.toLowerCase());
      this.setToken(token);
    }
  }

  /**
   * This takes the token that was retrieved in checkHeaders() and stores it in localStorage.
   * @param token
   */
  private setToken(token: string) {
    if (token) {
      localStorage.setItem(this.tokenName, token.replace('bearer', 'Bearer'));
    }
  }

  /**
   * Retrieve token from localStorage
   * @returns {string}
   */
  public getToken(): string {
    return localStorage.getItem(this.tokenName);
  }

  /**
   * This is to clear the token after login out of the app.
   */
  public clearToken() {
    localStorage.removeItem(this.tokenName);
  }

  /**
   * Clears the authentication token of the user and navigates back to the login page.
   */
  public logout() {
    // if (this.studyPivots.length) {
    //   if (this.activityTracker) {
    //     const participantActivityTracker = {
    //       activityTracker: this.activityTracker,
    //       participantId: this.studyPivots[0].participant_id
    //     };
    //     localStorage.setItem('participantActivityTracker', JSON.stringify(participantActivityTracker));
    //     this.store.dispatch(new ActivityTrackerActions.ClearState());
    //   }
    // }
    localStorage.removeItem('hash');
    this.clearToken();
    this.router.navigate(['login']);
  }
}
