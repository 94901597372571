export class ModuleItemRoutingGroupExpressionModel {
  id?: number;
  routingGroupId?: number;
  moduleItemId?: number;
  operator?: string;
  answer?: string;
  createdAt?: string;
  updatedAt?: string;

  constructor() {}

  saveExpressionData(data) {
    this.id = data.id;
    this.routingGroupId = data.routing_group_id;
    this.moduleItemId = data.module_item_id;
    this.operator = data.operator;
    this.answer = data.answer;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
  }
}

