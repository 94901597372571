import { ConfigurationModel } from './configuration/configuration.model';
import { ConfigurationBase } from './configuration/configuration.base';

export class Implementation extends ConfigurationBase {

  public API_URL = 'https://api.tech4people-apps.bms.utwente.nl/v1/frontend';
  public MEDIA_URL = 'https://api.tech4people-apps.bms.utwente.nl/storage/';
  public ENV: string = 'prod';
  public PRIVACY_POLICY = 'https://bmslab.utwente.nl/tiim-privacy/';
}

export const ENV: ConfigurationModel = new Implementation();

export const environment = {
  production: true
};

