import { InterventionModel } from './intervention.model';
import { StudyModel } from './study.model';

export class StudyPivotModel {
  id?: number;
  participantId?: number;
  studyId?: number;
  interventionId?: number;
  rejected?: number;
  language?: string;
  notifiedAt?: string;
  delayedStart?: string;
  createdAt?: string;
  updatedAt?: string;
  intervention?: InterventionModel;
  study?: StudyModel;

  saveStudyPivotData(data) {
    this.id = data.id;
    this.participantId = data.participant_id;
    this.studyId = data.study_id;
    this.interventionId = data.intervention_id;
    this.rejected = data.rejected;
    this.language = data.language;
    this.notifiedAt = data.notified_at;
    this.delayedStart = data.delayed_start;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
    this.intervention = new InterventionModel();
    this.study = new StudyModel();
    this.study.saveStudyData(data.study)
  }

  saveIntervention(data) {
    this.intervention.saveInterventionData(data);
  }
}
