export class ModuleTranslationModel {
  id?: number;
  moduleId?: number;
  language?: string;
  identifier?: string;
  translation?: string;
  createdAt?: string;
  updatedAt?: string;

  saveModuleTranslationData(data) {
    this.id = data.id;
    this.moduleId = data.module_id;
    this.language = data.language;
    this.identifier = data.identifier;
    this.translation = data.translation;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
  }
}
