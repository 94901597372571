import { ConfigurationModel } from './configuration.model';

export abstract class ConfigurationBase implements ConfigurationModel {
  public abstract readonly API_URL: string;

  public abstract readonly MEDIA_URL: string;

  public abstract readonly ENV: string;

  public abstract readonly PRIVACY_POLICY: string;

  isProd(): boolean {
    return false;
  }
}