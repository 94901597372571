export class OrbitItemModel {
  id?: number;
  moduleItemId?: number;
  name?: string;
  mainItem?: number;
  icon?: string;
  iconCode?: string;

  saveObitItemData(data) {
    this.id = data.id;
    this.moduleItemId = data.module_item_id;
    this.name = data.name;
    this.mainItem = data.main_item;
    this.icon = data.icon;
    this.iconCode = data.icon_code;
  }
}
