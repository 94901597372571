export class StudyTranslationModel {
  id?: number;
  studyId?: number;
  language?: string;
  identifier?: string;
  translation?: string;
  createdAt?: string;
  updatedAt?: string;

  saveStudyTranslationData(data) {
    this.id = data.id;
    this.studyId = data.study_id;
    this.language = data.language;
    this.identifier = data.identifier;
    this.translation = data.translation;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
  }
}
