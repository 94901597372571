import { ModuleModel } from './module.model';

export class ModulePivotModel {
  id?: number;
  answersSent?: boolean;
  answersSentOn?: string;
  interventionId?: number;
  moduleId?: number;
  sortOrder?: number;
  isCompassStart?: number;
  createdAt?: string;
  updatedAt?: string;
  module?: ModuleModel;
  participantModulesId?: number;

  saveModulePivotData(data) {
    this.id = data.id;
    this.answersSent = data.answers_sent;
    this.answersSentOn = data.answers_sent_on;
    this.interventionId = data.intervention_id;
    this.moduleId = data.module_id;
    this.sortOrder = data.sort_order;
    this.isCompassStart = data.is_compass_start;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
    this.module = new ModuleModel();
    this.participantModulesId = data.participant_modules_id;
    this.module.saveModuleModelData(data.module);
  }
}
