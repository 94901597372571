import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {ProfileService} from './services/profile.service';
import {SplashScreen} from "@capacitor/splash-screen";
import {timer} from "rxjs";
import {Location} from "@angular/common";
import {InterventionService} from "./services/intervention.service";
import {StudyPivotModel} from "./models/study-pivot.model";
import {PushNotificationsService} from "./services/push-notifications.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  showSplash = true;

  constructor(public router: Router,
              private authService: AuthService,
              private translate: TranslateService,
              private profileService: ProfileService,
              private location: Location,
              private interventionService: InterventionService,
              private pushNotificationService: PushNotificationsService) {
    SplashScreen.hide();

    timer(2000).subscribe(() => this.showSplash = false);

    this.setStartPage();
    this.setDefaultAppLanguage();
  }

  ngOnInit(): void {
    /**
     * Change the current language of the app when it's updated
     */
    this.profileService.getAppLanguageAsObservable().subscribe((response) => {
      this.translate.use(response);
    });
  }

  /**
   * If the token exists, go to home page. If not, go to login page.
   */
  setStartPage() {
    if (this.location.path().includes('pwdreset')) {
      //This will let the pwdreset link from the email threw.
    } else if (this.authService.getToken()) {
      this.profileService.getProfile().subscribe(response => {
        this.router.navigate(['home']);
        this.pushNotificationService.pushNotifications();
      }, error => {
        this.authService.clearToken();
        this.router.navigate(['login']);
      });
    } else if (this.location.path().includes('preview')) {
      localStorage.clear();

      let url_parts = this.location.path().split('/')
      const studyHash = url_parts[url_parts.length - 2]
      const interventionId = url_parts[url_parts.length - 1]

      localStorage.setItem('studyHash', studyHash);
      localStorage.setItem('interventionId', '' + interventionId);

      this.loadPreview(studyHash, interventionId);
    } else {
      this.router.navigate(['login']);
    }
  }

  loadPreview(studyHash, interventionId) {
    this.interventionService.getPreviewIntervention(studyHash, interventionId).subscribe((response: any) => {

      const studyPivot = new StudyPivotModel();

      studyPivot.saveStudyPivotData({
        study: response.study,
        study_id: response.study.id,
      });

      studyPivot.saveIntervention(response.intervention);

      this.router.navigate(['intervention'], {
        state: {
          preview: true,
          studyPivot: studyPivot
        }
      });
    });
  }

  /**
   * Set the default language to the app to English when the app is first opened (or the user has emptied the storage
   * of the app / uninstalled and reinstalled the app).
   */
  setDefaultAppLanguage() {
    const language = localStorage.getItem('Application Language');
    if (language) {
      this.translate.setDefaultLang(language);
    } else {
      this.translate.setDefaultLang(this.getDeviceLanguage());
    }
  }

  /**
   * Returns a string representing the preferred language of the user, usually the language of the browser UI.
   * @returns string
   */
  getDeviceLanguage(): string {
    let agentLanguage = navigator.language.substr(0, 2);

    if (agentLanguage !== 'en' && agentLanguage !== 'de' && agentLanguage !== 'nl') {
      agentLanguage = 'en';
    }
    localStorage.setItem('Application Language', agentLanguage);
    return agentLanguage;
  }
}
