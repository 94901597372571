import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'Login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'options',
    loadChildren: () => import('./pages/options/options.module').then( m => m.OptionsPageModule)
  },
  {
    path: 'add-study',
    loadChildren: () => import('./pages/add-study/add-study.module').then( m => m.AddStudyPageModule)
  },
  {
    path: 'qr-scanner',
    loadChildren: () => import('./pages/qr-scanner/qr-scanner.module').then( m => m.QrScannerPageModule)
  },
  {
    path: 'intervention',
    loadChildren: () => import('./pages/intervention/intervention.module').then( m => m.InterventionPageModule)
  },
  {
    path: 'pwdreset/:token',
    loadChildren: () => import('./pages/pwdreset/pwdreset.module').then(m => m.PwdresetPageModule)
  },
  {
    path: 'module-item',
    loadChildren: () => import('./pages/module-item/module-item.module').then( m => m.ModuleItemPageModule)
  },
  {
    path: 'finished-module',
    loadChildren: () => import('./pages/finished-module/finished-module.module').then( m => m.FinishedModulePageModule)
  },
  {
    path: 'enrol/:hash',
    loadChildren: () => import('./pages/enrolment/enrol/enrol.module').then( m => m.EnrolPageModule)
  },
  {
    path: 'thanks',
    loadChildren: () => import('./pages/enrolment/thanks/thanks.module').then(m => m.ThanksPageModule)
  },
  {
    path: 'language',
    loadChildren: () => import('./pages/enrolment/language/language.module').then( m => m.LanguagePageModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/enrolment/pages/pages.module').then( m => m.PagesPageModule)
  },
  {
    // this route is here to prevent an ionic error, it is handled in the app.component.ts
    path: 'preview/:hash/:interventionId',
    children: []
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
