import {StudyTranslationModel} from "./study-translation.model";
import {ModulePivotModel} from "./module-pivot.model";

export class StudyModel {
  id?: number;
  researcherId?: number;
  type?: string;
  name?: string;
  description?: string;
  startsAt?: string;
  endsAt?: string;
  status?: string;
  languages?: string;
  imagePath?: string;
  hash?: string;
  feedback?: number;
  feedbackTemplateId?: number;
  sendReportAfter?: number;
  healthEnabled?: number;
  phenotypingEnable?: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  emailRequired?: number;
  isFeedbackSimple?: number;
  simpleFeedbackId?: number;
  translations?: StudyTranslationModel[];
  currLang?: string;
  //enrolment?: EnrolmentModel;
  color?: string;

  saveStudyData(data) {
    this.id = data.id;
    this.researcherId = data.researcher_id;
    this.type = data.type;
    this.name = data.name;
    this.description = data.description;
    this.startsAt = data.starts_at;
    this.endsAt = data.ends_at;
    this.status = data.status;
    this.languages = data.languages;
    this.imagePath = data.image_path;
    this.hash = data.hash;
    this.feedback = data.feedback;
    this.feedbackTemplateId = data.feedback_template_id;
    this.sendReportAfter = data.send_report_after;
    this.healthEnabled = data.health_enabled;
    this.phenotypingEnable = data.phenotyping_enabled;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
    this.deletedAt = data.deleted_at;
    this.emailRequired = data.email_required;
    this.isFeedbackSimple = data.is_feedback_simple;
    this.simpleFeedbackId = data.simple_feedback_id;
    this.translations = []
    for (let i = 0; i < data.translations.length; i++) {
      let translation = new StudyTranslationModel();
      translation.saveStudyTranslationData(data.translations[i])
      this.translations.push(translation);
    }
    this.translations = data.translations;
    this.currLang = data.currLang;
    //this.enrolment = data.enrolment;
    this.color = data.color;
  }
}
