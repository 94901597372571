import {ModulePivotModel} from './module-pivot.model';

export class InterventionModel {
  id?: number;
  studyId?: number;
  informationPageId?: number;
  moduleId?: number;
  hash?: string;
  code?: string;
  codeExpired?: number;
  name?: string;
  description?: string;
  startFromPhone?: number;
  imagePath?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  orbitAnswer?: string;
  networkAnswer?: string;
  modulePivots?: ModulePivotModel[];

  saveInterventionData(data) {
    this.id = data.id;
    this.studyId = data.study_id;
    this.informationPageId = data.information_page_id;
    this.moduleId = data.module_id;
    this.hash = data.hash;
    this.code = data.code;
    this.codeExpired = data.code_expired;
    this.name = data.name;
    this.description = data.description;
    this.startFromPhone = data.start_from_phone;
    this.imagePath = data.image_path;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
    this.deletedAt = data.deleted_at;
    this.orbitAnswer = data.orbit_answer;
    this.networkAnswer = data.network_answer;
    this.modulePivots = [];
    if (data.module_pivots) {
      for (let i = 0; i < data.module_pivots.length; i++) {
        let modulePivot = new ModulePivotModel();
        modulePivot.saveModulePivotData(data.module_pivots[i]);
        this.modulePivots.push(modulePivot);
      }
    }
  }
}
