import { Injectable } from '@angular/core';
import {Platform, ToastController} from "@ionic/angular";
import {PushNotifications} from '@capacitor/push-notifications';
import {ProfileService} from "./profile.service";

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  constructor(private platform: Platform,
              private toastCtrl: ToastController,
              private profileService: ProfileService) {}

  pushNotifications() {
    if (this.platform.is('mobile')) {
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          PushNotifications.register();
        }
      });

      PushNotifications.addListener('registration', (token) => {
        this.sendPushData(token.value);
      })

      PushNotifications.addListener('registrationError', (err)=> {
        console.log(err);
      });



      PushNotifications.addListener('pushNotificationReceived', async (event: any) => {
        const payload = event.detail;

        if (!payload.wasTapped) {
          // Received in foreground
          const toast = await this.toastCtrl.create({
            message: payload.body,
            duration: 3000,
            color: 'success',
            animated: true,
          });
          toast.present();
        }
      });
    }
  }

  sendPushData(pushId) {
    let device;
    if (this.platform.is('ios')) device = 'IOS';
    if (this.platform.is('android')) device = 'Android';
    this.profileService.updatePushData(pushId, device).subscribe(response => {
      console.log(response);
    })
  }
}
