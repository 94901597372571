import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class InterventionService {

  constructor(public http?: HttpClient ) {}

  /**
   * Get the preview of intervention by study id and intervention id
   * @param studyHash
   * @param interventionId
   */
  getPreviewIntervention(studyHash: string, interventionId: number) {
    return this.http.get(`study/preview/${studyHash}/${interventionId}`);
  }

  /**
   * Get intervention by study id
   * @param studyId
   */
  getIntervention(studyId: number) {
    return this.http.get('study/' + studyId);
  }

  /**
   * Get intervention by study id
   * @param studyId
   */
  getInterventionOverview(studyId: number) {
    return this.http.get('study/' + studyId + '/overview');
  }

  /**
   * Get finished modules by study id
   * @param studyId
   */
  getResponses(studyId: number) {
    return this.http.get('responses/' + studyId);
  }
}
