import {ModuleItemRoutingGroupModel} from "./module-item-routing-group.model";

export class ModuleItemRoutingModel {
  id?: number;
  moduleItemId?: number;
  otherwiseRouteToId?: number;
  otherwiseRouteToSpecial?: string;
  routingGroups?: ModuleItemRoutingGroupModel[];
  createdAt?: string;
  updatedAt?: string;
  constructor(

  ) {}

  saveModuleItemRoutingData(data) {
    if (data) {
      this.id = data.id;
      this.moduleItemId = data.module_item_id;
      this.otherwiseRouteToId = data.otherwise_route_to_id;
      this.otherwiseRouteToSpecial = data.otherwise_route_to_special;
      this.routingGroups = []
      if (data.routing_groups) {
        for (let i = 0; i < data.routing_groups.length; i++) {
          let routingGroup = new ModuleItemRoutingGroupModel();
          routingGroup.saveModuleItemRoutingGroup(data.routing_groups[i]);
          this.routingGroups.push(routingGroup);
        }
      }
      this.createdAt = data.created_at;
      this.updatedAt = data.updated_at;
    }
  }
}
