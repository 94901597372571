import { ModuleItemRoutingGroupExpressionModel } from './module-item-routing-group-expression.model';

export class ModuleItemRoutingGroupModel {
  id?: number;
  routingId?: number;
  operator?: string;
  routeToId?: number;
  routeToSpecial?: string;
  expressions?: ModuleItemRoutingGroupExpressionModel[];
  createdAt?: string;
  updatedAt?: string;

  constructor() {}

  saveModuleItemRoutingGroup(data) {
    this.id = data.id;
    this.routingId = data.routing_id;
    this.operator = data.operator;
    this.routeToId = data.route_to_id;
    this.routeToSpecial = data.route_to_special;
    this.expressions = [];
    if (data.expressions) {
      for (let i = 0; i < data.expressions.length; i++) {
        let expression = new ModuleItemRoutingGroupExpressionModel();
        expression.saveExpressionData(data.expressions[i]);
        this.expressions.push(expression);
      }
    }
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
  }
}
