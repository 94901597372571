import {Injectable} from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';


import {Observable, throwError} from 'rxjs';

import {ENV} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {
  private apiUrl = ENV.API_URL;

  constructor(private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({url: this.prepareUrl(request.url)});
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.router.navigate(['login']);
        }
        return throwError(error);
      })
    );
  }

  private isAbsoluteUrl(url: string): boolean {
    if (url.includes('assets')) {
      return true;
    }
    const absolutePattern = /^https?:\/\//i;
    return absolutePattern.test(url);
  }

  private prepareUrl(url: string): string {
    url = this.isAbsoluteUrl(url) ? url : this.apiUrl + '/' + url;
    return url.replace(/([^:]\/)\/+/g, '$1');
  }

}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  protected excludedRoutes = ['login', 'pwd/forgot', 'pwd/reset', 'enrol', 'exists', 'create', 'study/preview'];

  constructor(public auth: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRouteExcluded(request.url)) {
      request = request.clone({
        setHeaders: {
          Authorization: this.auth.getToken()
        }
      });
    }

    return next.handle(request);
  }

  private isRouteExcluded(url: string): boolean {
    for (const route of this.excludedRoutes) {
      if (url.includes(ENV.API_URL + '/' + route)) {
        return true;
      }
    }
    return false;
  }
}
