import {ModuleTranslationModel} from "./module-translation.model";
import {ModuleItemModel} from "./module-item.model";
import {NumberResponseModel} from "./number-response.model";
import {RandomizationType} from "../../helpers/RandomizationType";
import {TextResponseModel} from "./text-response.model";
import {IndexResponseModel} from "./index-response.model";
import {OrbitResponseModel} from "./orbit-response.model";
import {NetworkResponseModel} from "./network-response.model";

export class ModuleModel {
  id?: number;
  studyId?: number;
  enrolment?: number;
  name?: string;
  imagePath?: string;
  remindAfter?: string;
  removeAfter?: string;
  heartRateType?: number;
  stepsType?: number;
  sleepType?: number;
  batteryLifeType?: number;
  locationType?: number;
  sensorDataTimePeriod?: number;
  sensorDataStart?: string;
  sensorDataEnd?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  progressBar?: boolean;
  randomization?: RandomizationType
  translations?: ModuleTranslationModel[];
  moduleItems?: ModuleItemModel[];
  numberResponses?: NumberResponseModel[];
  textResponses?: TextResponseModel[];
  indexResponses?: IndexResponseModel[];
  orbitResponses?: OrbitResponseModel[];
  networkResponses?: NetworkResponseModel[];

  saveModuleModelData(data) {
    if (data !== null) {
      this.id = data.id;
      this.studyId = data.study_id;
      this.enrolment = data.enrolment;
      this.name = data.name;
      this.imagePath = data.image_path;
      this.remindAfter = data.remind_after;
      this.removeAfter = data.remove_after;
      this.heartRateType = data.heart_rate_type;
      this.stepsType = data.steps_type;
      this.sleepType = data.sleep_type;
      this.batteryLifeType = data.battery_life_type;
      this.locationType = data.location_type;
      this.sensorDataTimePeriod = data.sensor_data_time_period;
      this.sensorDataStart = data.sensor_data_start;
      this.sensorDataEnd = data.sensor_data_end;
      this.createdAt = data.created_at;
      this.updatedAt = data.updated_at;
      this.deletedAt = data.deleted_at;
      this.progressBar = data.progress_bar;
      this.randomization = data.randomization;
      this.translations = [];
      for (let i = 0; i < data.translations?.length; i++) {
        let translation = new ModuleTranslationModel();
        translation.saveModuleTranslationData(data.translations[i]);
        this.translations.push(translation);
      }
      this.moduleItems = [];
      for (let i = 0; i < data.module_items?.length; i++) {
        let moduleItem = new ModuleItemModel();
        moduleItem.saveModuleItemData(data.module_items[i]);
        this.moduleItems.push(moduleItem);
      }
      this.numberResponses = [];
      if (data.number_responses) {
        for (let i = 0; i < data.number_responses?.length; i++) {
          let numberResponse = new NumberResponseModel();
          numberResponse.saveNumberResponseData(data.number_responses[i]);
          this.numberResponses.push(numberResponse);
        }
      }
      this.textResponses = [];
      if (data.text_responses) {
        for (let i = 0; i < data.text_responses?.length; i++) {
          let textResponse = new TextResponseModel();
          textResponse.saveTextResponseData(data.text_responses[i]);
          this.textResponses.push(textResponse);
        }
      }
      this.indexResponses = [];
      if (data.index_responses) {
        for (let i = 0; i < data.index_responses?.length; i++) {
          let indexResponse = new IndexResponseModel();
          indexResponse.saveIndexResponseData(data.index_responses[i]);
          this.indexResponses.push(indexResponse);
        }
      }
      this.orbitResponses = [];
      if (data.orbit_responses) {
        for (let i = 0; i < data.orbit_responses?.length; i++) {
          let orbitResponse = new OrbitResponseModel();
          orbitResponse.saveOrbitResponseData(data.orbit_responses[i]);
          this.orbitResponses.push(orbitResponse);
        }
      }
      this.networkResponses = [];
      if (data.network_responses) {
        for (let i = 0; i < data.network_responses?.length; i++) {
          let networkResponse = new NetworkResponseModel();
          networkResponse.saveNetworkResponseData(data.network_responses[i]);
          this.networkResponses.push(networkResponse);
        }
      }
    }
  }
}
