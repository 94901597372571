import {ModuleItemAttributeModel} from "./module-item-attribute.model";
import {ModuleItemTranslationModel} from "./module-item-translation.model";
import {OrbitItemModel} from "./orbit-item.model";
import {ModuleItemRoutingModel} from "./module-item-routing.model";


export class ModuleItemModel {
  id?: number;
  moduleId?: number;
  description?: string;
  type?: string;
  subType?: string;
  sortOrder?: number;
  numberOrbits?: string;
  imagePath?: string;
  videoPath?: string;
  audioPath?: string;
  videoUrl?: string;
  forCalculation?: string;
  calculationName?: string;
  createdAt?: string;
  updatedAt?: string;
  reuseOrbitItems?: number;
  translations?: ModuleItemTranslationModel[];
  routing?: ModuleItemRoutingModel;
  orbitItems?: OrbitItemModel[]
  attributes?: ModuleItemAttributeModel[];


  saveModuleItemData(data) {
    this.id = data.id;
    this.moduleId = data.module_id;
    this.description = data.description;
    this.type = data.type;
    this.subType = data.sub_type;
    this.sortOrder = data.sort_order;
    this.numberOrbits = data.number_orbits;
    this.imagePath = data.image_path;
    this.videoPath = data.video_path;
    this.audioPath = data.audio_path;
    this.videoUrl = data.video_url;
    this.forCalculation = data.for_calculation;
    this.calculationName = data.calculation_name;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
    if (data.routing) {
      this.routing = new ModuleItemRoutingModel();
      this.routing.saveModuleItemRoutingData(data.routing);
    }
    this.translations = []
    for (let i = 0; i < data.translations.length; i++) {
      let translation = new ModuleItemTranslationModel();
      translation.saveTranslationData(data.translations[i]);
      this.translations.push(translation);
    }
    this.orbitItems = []
    if (data.orbit_items) {
      for (let i = 0; i < data.orbit_items.length; i++) {
        let orbitItem = new OrbitItemModel();
        orbitItem.saveObitItemData(data.orbit_items[i]);
        this.orbitItems.push(orbitItem);
      }
    }
    this.attributes = [];
    for (let i = 0; i < data.attributes.length; i++) {
      let attribute = new ModuleItemAttributeModel()
      attribute.saveModuleItemAttributeData(data.attributes[i]);
      this.attributes.push(attribute);
    }
  }
}
