export class TextResponseModel {
  id?: number;
  moduleId?: number;
  moduleItemId?: number;
  participantId?: number;
  response?: string;
  responseTimingId?: number;
  participantModulesId?: number;

  saveTextResponseData(data) {
    this.id = data.id;
    this.moduleId = data.module_id;
    this.moduleItemId = data.module_item_id;
    this.participantId = data.participant_id;
    this.response = data.response;
    this.responseTimingId = data.response_timing_id;
    this.participantModulesId = data.participant_modules_id;
  }
}
