import {ModuleItemAttributeTranslationModel} from "./module-item-attribute-translation.model";

export class ModuleItemAttributeModel {
  id?: number;
  moduleItemId?: number;
  attribute?: string;
  value?: string;
  createdAt?: string;
  updatedAt?: string;
  translations?: ModuleItemAttributeTranslationModel[];

  saveModuleItemAttributeData(data) {
    this.id = data.id;
    this.moduleItemId = data.module_item_id;
    this.attribute = data.attribute;
    this.value = data.value;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
    this.translations = []
    for (let i = 0; i < data.translations.length; i++) {
      let translation = new ModuleItemAttributeTranslationModel();
      translation.saveModuleItemAttributeTranslationData(data.translations[i]);
      this.translations.push(translation);
    }
  }
}
